import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'components';

export default Loadable({
  loader: () => import('./CollectionsIndex'),
  loading: () => <Loading withContentWrapper />,
});

export const List = Loadable({
  loader: () => import('./CollectionsList'),
  loading: () => <Loading withContentWrapper />,
});

export const Form = Loadable({
  loader: () => import('./CollectionsForm'),
  loading: () => <Loading withContentWrapper />,
});

export const Content = Loadable({
  loader: () => import('./CollectionContent'),
  loading: () => <Loading withContentWrapper />,
});
