import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import Collapse from 'components/Collapse';
import { FileInfo } from 'infrastructure/uploads/UploadsContext';
import UploadSummaryHeader from './UploadSummaryHeader';
import FileUploadRow from './FileUploadRow';

import './UploadSummary.scss';

export interface UploadSummaryProps {
  /** Called when the cancel button is selected */
  cancelFunc?: () => void;
  clearUploads?: () => void;
  files: readonly FileInfo[];
  /** Show the cancel button */
  showCancelButton?: boolean;
}

const UploadSummary: React.FC<UploadSummaryProps> = ({
  cancelFunc,
  clearUploads,
  files,
  showCancelButton = false,
}) => {
  const showUploader = files.length !== 0;

  return (
    <CSSTransition
      in={showUploader}
      classNames="upload-summary"
      timeout={350}
      unmountOnExit
    >
      <Collapse
        className={classnames('upload-summary', {
          'has-cancel': !!cancelFunc && showCancelButton,
        })}
        customTrigger={
          <UploadSummaryHeader
            cancelFunc={cancelFunc}
            clearUploads={clearUploads}
            files={files}
            showCancelButton={showCancelButton}
          />
        }
        defaultOpen={false}
      >
        <div className="file-list-container light-scrollbar">
          {files.map(file => (
            <FileUploadRow file={file} key={file.id} />
          ))}
        </div>
      </Collapse>
    </CSSTransition>
  );
};

export default UploadSummary;
