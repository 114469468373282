import React from 'react';
import cx from 'classnames';

import './DefinitionList.scss';

/** Wrapper for HTML definition list: `<dl />` */

interface DefinitionListProps {
  alignLeft?: boolean;
  tableLayout?: boolean;
  terms: {
    key?: string | number;
    name: string;
    definition: string | React.ReactNode;
  }[];
}

const DefinitionList: React.FC<DefinitionListProps> = ({
  alignLeft,
  tableLayout,
  terms = [],
}) => (
  <dl
    className={cx({ 'definition-table': tableLayout, 'align-left': alignLeft })}
  >
    {terms
      .filter(x => x)
      .map((term, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={term.key || i}>
          <dt>{term.name}: </dt>
          <dd>{term.definition}</dd>
        </div>
      ))}
  </dl>
);

export default DefinitionList;
