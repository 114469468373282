import { Loading } from 'components';
import React from 'react';
import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./ActionsIndex'),
  loading: () => <Loading withContentWrapper />,
});

export const List = Loadable({
  loader: () => import('./ActionsList'),
  loading: () => <Loading withContentWrapper />,
});

export const Form = Loadable({
  loader: () => import('./ActionsFormPage'),
  loading: () => <Loading withContentWrapper />,
});
