const SignalEvent = {
  ResourceProcessed: 'ResourceProcessed',
  ResourceError: 'ResourceError',
  CategoriesImportComplete: 'CategoriesImportComplete',
  CategoriesImportFailed: 'CategoriesImportFailed',
  UniqueViewersQueryComplete: 'UniqueViewersQueryComplete',
  UniqueViewersQueryFailed: 'UniqueViewersQueryFailed',
  UniqueClipViewersQueryComplete: 'UniqueClipViewersQueryComplete',
  UniqueClipViewersQueryFailed: 'UniqueClipViewersQueryFailed',
  StoryZipDownloadComplete: 'StoryZipDownloadComplete',
  StoryThumbnailProcessed: 'StoryThumbnailProcessed',
  StoryThumbnailError: 'StoryThumbnailError',
  StoryIconProcessed: 'StoryIconProcessed',
  StoryIconError: 'StoryIconError',
  AnalyticsExportComplete: 'AnalyticsExportComplete',
  ClipAnalyticsExportComplete: 'ClipAnalyticsExportComplete',
  TriviaQuizComplete: 'TriviaQuizComplete',
  RecipeRunComplete: 'RecipeRunComplete',
  RecipeRunFailed: 'RecipeRunFailed',
  ContentBankAssetGenerationSuccess: 'ContentBankAssetGenerationSuccess',
  ContentBankAssetGenerationError: 'ContentBankAssetGenerationError',
  RecipeStepUpdated: 'RecipeStepUpdated',
  ClipTranscriptionSuccess: 'ClipTranscriptionSuccess',
  ClipTranscriptionError: 'ClipTranscriptionError',
};

export default SignalEvent;
