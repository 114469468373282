import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'components';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading withContentWrapper />,
});

export const Form = Loadable({
  loader: () => import('./FormPage'),
  loading: () => <Loading withContentWrapper />,
});

export const List = Loadable({
  loader: () => import('./WSCConfigList'),
  loading: () => <Loading withContentWrapper />,
});
