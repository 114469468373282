import React, { Fragment, createElement, useMemo } from 'react';
import { FormFeedback, Input, InputProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/Button/Button';
import Loading from 'components/Loading/Loading';
import { ValidationStatus } from 'shared/helpers/isFieldValid';
import { isCustomIcon, isIconButton, type TextFieldIcon } from './findIconType';

import './TextField.scss';

const MAX_TIME_FIELD_LENGTH = 5;

export interface TextFieldProps extends InputProps {
  /** Input name & ID */
  name?: string;
  id?: string;
  /** Input type, others are supported in HTML but there should be separate components for those */
  type?:
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'textarea'
    | 'url'
    | 'time';
  /** Validation array */
  maxLength?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value?: string;
  errors?: string;
  isTouched?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  className?: string;
  /** For testing purposes */
  textFieldId?: string;
  isValid?: ValidationStatus | boolean;
  disabled?: boolean;
  loading?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  innerRef?: React.Ref<HTMLInputElement>;
  rows?: number;
  /** Optional button/icon to show after the input */
  append?: TextFieldIcon;
  /** Optional button/icon to show before the input */
  prepend?: TextFieldIcon;
  disableValidationStyles?: boolean;
}

/** Generic input element with some restrictions to ensure better usage */
const TextField: React.FC<TextFieldProps> = props => {
  const {
    id = '',
    name = '',
    type = 'text',
    maxLength,
    onChange,
    placeholder = '',
    value = '',
    errors = '',
    isTouched = false,
    onBlur,
    className,
    textFieldId,
    isValid,
    disabled = false,
    loading = false,
    required = false,
    autoFocus = false,
    append,
    prepend,
    disableValidationStyles,
    ...rest
  } = props;

  const renderIcon = (icon: TextFieldIcon, position: 'append' | 'prepend') => {
    const containerClassName = `form__form-group-icon input-group-${position}`;

    if (isCustomIcon(icon)) {
      return icon(containerClassName);
    }

    const { color, name: iconName, ...iconProps } = icon;

    const iconEl = <FontAwesomeIcon icon={iconName} />;

    const containerColor = color ?? 'secondary';

    if (isIconButton(iconProps)) {
      return (
        <Button
          color={containerColor}
          className={containerClassName}
          icon={iconEl}
          {...iconProps}
        />
      );
    }

    return (
      <div className={`${containerClassName} btn btn-${containerColor}`}>
        {iconEl}
      </div>
    );
  };

  const hasIcon = prepend || append || loading;

  const valid = useMemo(() => {
    if (disableValidationStyles || !isTouched || rest.readOnly) {
      return false;
    }
    if (typeof isValid === 'boolean') {
      return isValid;
    }
    if (isValid === ValidationStatus.Valid) {
      return true;
    }
    return false;
  }, [disableValidationStyles, isTouched, isValid, rest.readOnly]);

  const invalid = useMemo(() => {
    if (disableValidationStyles || !isTouched || rest.readOnly) {
      return false;
    }
    if (typeof isValid === 'boolean') {
      return !isValid;
    }
    if (isValid === ValidationStatus.Invalid) {
      return true;
    }
    return false;
  }, [disableValidationStyles, isTouched, isValid, rest.readOnly]);

  return createElement(
    hasIcon ? 'div' : Fragment,
    hasIcon ? { className: 'form__form-group-field' } : {},
    <>
      {prepend && renderIcon(prepend, 'prepend')}
      <Input
        disabled={disabled}
        id={id || name}
        maxLength={type === 'time' ? MAX_TIME_FIELD_LENGTH : maxLength}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        pattern={
          type === 'time' ? '(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]' : undefined
        }
        className={`${className ?? ''} ${prepend ? 'input-icon-left' : ''} ${
          append || loading ? 'input-icon-right' : ''
        }`}
        placeholder={placeholder}
        type={type}
        value={value}
        data-testid={`${textFieldId}__textInput`}
        invalid={invalid}
        valid={valid}
        required={required}
        autoFocus={autoFocus}
        {...rest}
      />
      {append && !loading && renderIcon(append, 'append')}
      {loading &&
        renderIcon(
          containerClassName => (
            <div className={`${containerClassName} btn btn-none`}>
              <Loading visible />
            </div>
          ),
          'append'
        )}
      {isTouched && errors ? (
        <FormFeedback
          data-testid={`${textFieldId}__textInputFormFeedback`}
          className="validation form__form-group-error error-message"
          tag="small"
        >
          {errors}
        </FormFeedback>
      ) : null}
    </>
  );
};

export default TextField;
