import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Measure from 'react-measure';

import 'lib/react-dates/initialize';
import { SingleDatePicker } from 'lib/react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';

/** Describe the component */
const defaultDatePattern = '06/30/2099';
const OpenDirection = {
  down: 'down',
  up: 'up',
};

const DateField = props => {
  const {
    allowPastDates,
    id,
    inputStyle,
    renderCalendarInfo,
    date,
    onDateChange,
    onClose,
    displayFormat,
    disabled,
    isOpen,
    onFocusChangeHandler,
    currentDate,
  } = props;

  const [dimensions, setDimensions] = useState({
    dimensions: {
      width: 80,
      height: 0,
    },
  });

  const onFocusChange = focus => {
    onFocusChangeHandler(focus.focused);
    if (!focus.focused) onClose();
  };

  const renderCalendarInfoFunc = () => renderCalendarInfo;

  const underlinedInput = inputStyle === 'underlined';

  const getDateTimeString = () => {
    let datePattern = displayFormat();
    if (datePattern) {
      datePattern = datePattern.replace('[', '').replace(']', '');
    } else {
      datePattern = defaultDatePattern;
    }

    return datePattern;
  };

  const datePickerElementRef = useRef();
  const [datePickerOpenDirection, setDatePickerOpenDirection] = useState(
    OpenDirection.down
  );

  useEffect(() => {
    const checkIfDatePickerInViewport = () => {
      const rect = datePickerElementRef.current.getBoundingClientRect();
      const calculatedOpenDirection =
        rect.top > window.innerHeight - rect.bottom
          ? OpenDirection.up
          : OpenDirection.down;
      setDatePickerOpenDirection(calculatedOpenDirection);
    };
    // Initial check
    checkIfDatePickerInViewport();

    window.addEventListener('resize', checkIfDatePickerInViewport);
    return () => {
      window.removeEventListener('resize', checkIfDatePickerInViewport);
    };
  }, []);

  return (
    <>
      <div
        className={classnames('datetime-field date-picker-popover', inputStyle)}
        style={{ width: dimensions.width }}
        data-testid={`DateField__container_${id}`}
      >
        <div
          className={classnames('date-picker', datePickerOpenDirection)}
          ref={datePickerElementRef}
        >
          <SingleDatePicker
            customInputIcon={
              underlinedInput ? null : <FontAwesomeIcon icon={faCalendarAlt} />
            }
            date={date}
            daySize={40}
            enableOutsideDays
            firstDayOfWeek={1}
            focused={isOpen}
            hideKeyboardShortcutsPanel
            horizontalMonthPadding={0}
            id={id}
            keepOpenOnDateSelect
            noBorder
            numberOfMonths={1}
            onDateChange={onDateChange}
            onFocusChange={onFocusChange}
            renderCalendarInfo={renderCalendarInfoFunc}
            transitionDuration={0}
            verticalHeight={260}
            {...(allowPastDates && { isOutsideRange: () => false })}
            displayFormat={displayFormat}
            disabled={disabled}
            initialVisibleMonth={currentDate}
            openDirection={datePickerOpenDirection}
            verticalSpacing={0}
          />
        </div>
      </div>
      <Measure
        bounds
        onResize={contentRect => setDimensions(contentRect.bounds)}
      >
        {({ measureRef }) => (
          <div className="functionally-hidden" ref={measureRef}>
            {getDateTimeString()}
          </div>
        )}
      </Measure>
    </>
  );
};

DateField.propTypes = {
  /** Allow user to select dates in the past */
  allowPastDates: PropTypes.bool,
  /** Adapt the width of the input based on it's contents */
  autoWidth: PropTypes.bool,
  /** Unique input ID */
  id: PropTypes.string.isRequired,
  /** Set the style of the input, contained is a regular box input while underlined is a more minimal style */
  inputStyle: PropTypes.oneOf(['contained', 'underlined']),
  /** Render a node under the day picker */
  renderCalendarInfo: PropTypes.node,
  displayFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onDateChange: PropTypes.func,
  date: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  onFocusChangeHandler: PropTypes.func,
};

DateField.defaultProps = {
  allowPastDates: true,
  autoWidth: false,
  inputStyle: 'contained',
  renderCalendarInfo: () => null,
  displayFormat: undefined,
  disabled: false,
  onClose: () => {},
  onDateChange: () => {},
  date: null,
  isOpen: undefined,
  onFocusChangeHandler: undefined,
};

export default DateField;
