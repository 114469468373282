import React from 'react';
import classnames from 'classnames';

import './Img.scss';

/** Can be used in place of a HTML `<img />` element, adds some props for a background blur.
 * Could handle loading state and more in future.
 */

interface Props {
  alt?: string;
  blurFill?: boolean;
  containerClassName?: string | null;
  imageClassName?: string | undefined;
  lightenBackground?: boolean;
  src?: string;
  title?: string | undefined;
}

const Img = ({
  alt = '',
  blurFill = false,
  containerClassName = null,
  imageClassName = undefined,
  lightenBackground = false,
  src = '',
  title = undefined,
}: Props) => (
  <div
    className={classnames('image-component', containerClassName, {
      'blur-fill': blurFill,
      lighten: lightenBackground,
    })}
  >
    <div className="image-size-container">
      <img src={src} alt={alt} title={title} className={imageClassName} />
    </div>
    {blurFill && (
      <div
        className="background"
        style={blurFill && { backgroundImage: `url(${src})` }}
      />
    )}
  </div>
);

export default Img;
