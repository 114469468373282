import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import logo from 'img/logo/storyteller-logo-white.svg';
import UserAvatar from 'components/UserAvatar';
import SupportDropdown from 'components/SupportDropdown/SupportDropdown';
import Loading from 'components/Loading/Loading';

import './GlobalAppWrapper.scss';
import '../GlobalTopbar/GlobalTopbar.scss';
import './BlankLoadingGlobalWrapper.scss';

const BlankLoadingGlobalWrapper: React.FC<{
  isDashboardView?: boolean;
}> = ({ isDashboardView }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Set sidebar open state to false so we don't get the overlay etc
  useEffect(() => {
    const handleResize = () => window.innerWidth > 1200 && setMenuOpen(false);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (!isDashboardView) {
    return (
      <div className="blank-loading">
        <Loading visible />
      </div>
    );
  }
  return (
    <div
      className={classnames('global-app-wrapper blank', {
        'sidebar-open': menuOpen,
        'sidebar-closed': !menuOpen,
      })}
    >
      <div className="global-topbar">
        <div className="topbar-left">
          <div
            className={classnames('unstyled-button topbar-hamburger', {
              'menu-open': menuOpen,
            })}
          >
            <div className="hamburger-bar">
              {menuOpen ? 'Close Navigation' : 'Open Navigation'}
            </div>
          </div>
          <img className="topbar-logo" src={logo} alt="Storyteller Logo" />
        </div>
        <div className="topbar-right">
          <SupportDropdown />
          <div className="topbar-dropdown-nav">
            <div className="topbar-dropdown-trigger">
              <UserAvatar />
            </div>
          </div>
        </div>
      </div>
      <div className="global-sidebar" role="navigation">
        <hr />
      </div>
      <Loading withContentWrapper />
    </div>
  );
};

export default BlankLoadingGlobalWrapper;
