import { Formik } from 'shared/helpers/formik';
export enum ValidationStatus {
  Valid = 'valid',
  Invalid = 'invalid',
  Untouched = 'untouched',
}
// Utility to access nested properties
const getNestedValue = (obj: any, path: string): any => {
  return path
    .split('.')
    .reduce((acc, key) => (acc ? acc[key] : undefined), obj);
};
// Checks if field has errors & if was touched
const isFieldValid = (field: string, formik: Formik<any>) => {
  const initialValue = getNestedValue(formik.initialValues, field);
  const currentValue = getNestedValue(formik.values, field);
  const touched = getNestedValue(formik.touched, field);
  const error = getNestedValue(formik.errors, field);
  if (initialValue === currentValue && !error) {
    return ValidationStatus.Untouched;
  } else if (!(touched && error)) {
    return ValidationStatus.Valid;
  } else {
    return ValidationStatus.Invalid;
  }
};
export default isFieldValid;
