import React, { useCallback, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { UserAvatar } from 'components';
import useCurrentUser from 'infrastructure/currentUser/useCurrentUser';
import useLogout from 'slices/AccountV2/hooks/useLogout';
import SupportDropdown from 'components/SupportDropdown/SupportDropdown';

const TopRightNav: React.FC<{}> = () => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const { currentUser } = useCurrentUser();
  const logout = useLogout({});

  const toggleProfileMenu = () => {
    setProfileMenuOpen(prevState => !prevState);
  };

  const handleLogout = useCallback(() => {
    logout.mutate();
  }, [logout]);

  return (
    <>
      <SupportDropdown />
      <Dropdown
        isOpen={profileMenuOpen}
        toggle={toggleProfileMenu}
        className="topbar-dropdown-nav"
      >
        <DropdownToggle
          className="topbar-dropdown-trigger"
          onClick={event => event.currentTarget.blur()}
          title="Account"
        >
          <UserAvatar userName={currentUser && currentUser.name} />
        </DropdownToggle>
        <CSSTransition in={profileMenuOpen} classNames="dropdown" timeout={200}>
          <DropdownMenu right>
            <DropdownItem onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOut} className="highlight-icon" />{' '}
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </CSSTransition>
      </Dropdown>
    </>
  );
};

export default TopRightNav;
