import React, { PropsWithChildren, ReactElement } from 'react';
import { Col, Label, Row } from 'reactstrap';
import classnames from 'classnames';
import './FormRow.scss';

/** A FormRow component for attaching labels to inputs and building forms */

export interface FormRowProps {
  align?: 'top' | 'center';
  borderless?: boolean;
  className?: string;
  helpText?: string;
  label: ReactElement | string;
  name: string;
  optional?: boolean;
  stacked?: boolean;
  toggleContainer?: boolean;
  toggleLeft?: boolean;
  subLabel?: string;
  labelColumnRatio?: number;
  labelTextAlign?: 'left' | 'right';
  fullWidth?: boolean;
}

const FormRow: React.FC<PropsWithChildren<FormRowProps>> = ({
  align = 'top',
  borderless = false,
  children,
  className,
  helpText,
  label,
  name,
  optional = false,
  stacked = false,
  toggleContainer = false,
  toggleLeft = false,
  subLabel,
  labelColumnRatio,
  labelTextAlign = 'left',
  fullWidth,
}) => {
  const columnRatio = labelColumnRatio
    ? [labelColumnRatio, 12 - labelColumnRatio]
    : [3, 9];
  const inlineLabelWidth = toggleLeft ? '' : columnRatio[0];

  return (
    <div
      className={classnames(
        className,
        'form__form-group',
        {
          borderless,
          optional,
          'toggle-container': toggleContainer,
          'toggle-left': toggleLeft,
        },
        stacked ? 'stacked' : 'horizontal'
      )}
    >
      <Row
        className={classnames('form-row', {
          'align-items-top': align === 'top' && !toggleContainer,
          'align-items-center': align === 'center' || toggleContainer,
          'no-gutters': toggleLeft,
        })}
      >
        {label && (
          <Label
            className={classnames(
              {
                'order-2 ml-3': toggleLeft,
                'text-right': labelTextAlign === 'right',
              },
              'form__form-group-label'
            )}
            htmlFor={name}
            md={fullWidth ? 'auto' : stacked ? 12 : inlineLabelWidth}
            xs={toggleContainer ? 'auto' : undefined}
          >
            {label}
            {optional && (
              <small
                className={classnames('help-text optional-flag', {
                  'ml-1': stacked,
                  'd-block': !stacked,
                })}
              >
                (Optional)
              </small>
            )}
            {!optional && subLabel && (
              <small
                className={classnames('help-text optional-flag', {
                  'ml-1': stacked,
                  'd-block': !stacked,
                })}
              >
                {subLabel}
              </small>
            )}
          </Label>
        )}
        <Col
          xs={fullWidth ? undefined : (toggleContainer && 'auto') || 12}
          md={
            fullWidth
              ? undefined
              : !toggleLeft && (!label || stacked ? 12 : columnRatio[1])
          }
        >
          <div className="input-container">
            <div className="form__form-group-input-wrap--error-below">
              {children}
              {helpText && (
                <small className="help-text d-block mt-1">{helpText}</small>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FormRow;
