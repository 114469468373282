import React from 'react';
import classnames from 'classnames';
import { Link } from 'components';
import { CSSTransition } from 'react-transition-group';
import ParentNavPoint from './ParentNavPoint';

import './GlobalSidebar.scss';

export interface GlobalSidebarLink {
  current: boolean;
  icon: React.ReactNode;
  url: string;
  name: React.ReactNode;
  type: string;
  elementId: string;
  counterKey?: string;
  items: Array<{
    current: boolean;
    url: string;
    name: React.ReactNode;
    counterKey?: string;
  }>;
}

export interface GlobalSidebarProps {
  /** Function to force the sidebar closed (for the overlay button) */
  closeSidebar: () => void;
  /** Is the sidebar open (mostly for phones & tablets, always true on desktop) */
  isOpen?: boolean;
  /** Show sidebar loading staet */
  loading?: boolean;
  /** Links array containing all nav points */
  links?: Array<GlobalSidebarLink>;
  /** Function to force the sidebar open (useful when expanding a parent nav point on tablet) */
  openSidebar: () => void;
}

const getTestId = (elementId: string) => elementId.replace(/ /g, '_');

const GlobalSidebar: React.FC<GlobalSidebarProps> = props => {
  const {
    closeSidebar,

    isOpen = false,
    links = [],
    loading = false,
    openSidebar,
  } = props;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
      <nav
        aria-expanded={isOpen}
        className={classnames('global-sidebar', { loading })}
        role="navigation"
      >
        <CSSTransition
          classNames="fade-right"
          in={loading}
          timeout={200}
          unmountOnExit
        >
          <div className="loading-ui" />
        </CSSTransition>
        <hr />
        {links.map((navPoint, i) =>
          navPoint.type
            ? {
                externalLink: (
                  <a
                    className="nav-point"
                    href={navPoint.url}
                    key={`nav-point-${navPoint.elementId}`}
                    rel="noreferrer noopener"
                    target="_blank"
                    data-testid={getTestId(navPoint.elementId)}
                  >
                    {navPoint.icon && (
                      <div className="icon">{navPoint.icon}</div>
                    )}
                    <div className="nav-point-title">{navPoint.name}</div>
                  </a>
                ),
                parent: (
                  <ParentNavPoint
                    // eslint-disable-next-line react/no-array-index-key
                    key={`nav-point-${i}`}
                    closeSidebar={closeSidebar}
                    navPoint={navPoint}
                    openSidebar={openSidebar}
                    sidebarIsOpen={isOpen}
                  />
                ),
                // eslint-disable-next-line react/no-array-index-key
                separator: <hr key={`nav-point-${i}`} />,
              }[navPoint.type]
            : navPoint.url && (
                <Link
                  className={classnames('nav-point', {
                    current: navPoint.current,
                  })}
                  key={`nav-point-${navPoint.elementId}`}
                  data-testid={getTestId(navPoint.elementId)}
                  onClick={closeSidebar}
                  to={navPoint.url}
                >
                  {navPoint.icon && <div className="icon">{navPoint.icon}</div>}
                  <div className="nav-point-title">{navPoint.name}</div>
                </Link>
              )
        )}
      </nav>
      <CSSTransition classNames="fade" in={isOpen} timeout={200} unmountOnExit>
        <button
          aria-label="Close Navigation"
          className="sidebar-overlay"
          onClick={closeSidebar}
          type="button"
        />
      </CSSTransition>
    </>
  );
};

export default GlobalSidebar;
