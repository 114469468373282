import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import Content from 'components/v2/Content/Content';

import './Loading.scss';

interface Props {
  className?: string;
  maskContainer?: boolean;
  delayShow?: boolean;
  minHeight?: number | string;
  visible?: boolean;
  size?: 'sm' | 'lg';
  withContentWrapper?: boolean;
}

const LoadingWrapper = ({
  children,
  withContentWrapper,
}: {
  children: ReactElement;
  withContentWrapper: boolean;
}) => {
  if (withContentWrapper) {
    return <Content>{children}</Content>;
  }

  return children;
};

const Loading = ({
  delayShow = false,
  minHeight,
  maskContainer = false,
  size,
  visible,
  className,
  withContentWrapper = false,
}: Props) => (
  <LoadingWrapper withContentWrapper={withContentWrapper}>
    <CSSTransition
      in={visible || withContentWrapper}
      classNames="loading"
      timeout={200}
      unmountOnExit
    >
      <div
        className={classnames('loading-container text-center', className, {
          'mask-container': maskContainer || withContentWrapper,
          'delay-show': delayShow,
          [`loading-${size}`]: !!size, // will produce e.g. "loading-sm" class name when size is 'sm'
        })}
        style={{
          minHeight,
        }}
      >
        <div className="load-icon-wrap">
          <FontAwesomeIcon icon={faSpinner} className="load-icon" spin />
        </div>
      </div>
    </CSSTransition>
  </LoadingWrapper>
);

export default Loading;
