import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './Breadcrumbs.scss';

export type PathType = {
  displayName: string;
  url: string;
  isCurrent?: boolean;
}[];

interface BreadcrumbsProps {
  path: PathType;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ path }) => (
  <Breadcrumb className="breadcrumbs">
    {path
      .filter(breadcrumb => !breadcrumb.isCurrent)
      .map(breadcrumb => (
        <BreadcrumbItem key={`${breadcrumb.displayName}_${breadcrumb.url}`}>
          {breadcrumb.url ? (
            <Link to={breadcrumb.url}>{breadcrumb.displayName}</Link>
          ) : (
            breadcrumb.displayName
          )}
        </BreadcrumbItem>
      ))}
  </Breadcrumb>
);

export default Breadcrumbs;
