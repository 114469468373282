import { Loading } from 'components';
import React from 'react';
import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading withContentWrapper />,
});

export const AnalyticsPage = Loadable({
  loader: () => import('./AnalyticsPage'),
  loading: () => null,
});
