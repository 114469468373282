import React, { useCallback, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { Button } from 'components';
import { useHistory } from 'react-router';

import './Tabs.scss';

const DEFAULT_ACTIVE_TAB = 0;
const TAB_INDEX_PARAM = 'tabIndex';

interface ContentItem<P = {}> {
  tabName: string;
  tabContent: JSX.Element;
  tabLinkProps?: P;
  tabIndex?: number;
  tabIcon?: JSX.Element;
}

type TabsBorder = 'default' | 'top' | 'bottom';

export type TabsProps = {
  border?: TabsBorder;
  className?: string;
  justify?: boolean;
  defaultActiveTab?: number;
  showButtons?: boolean;
  stickyNav?: boolean;
  size?: 'sm' | 'lg' | null;
  topRightContent?: JSX.Element;
  content: ContentItem[];
  linkable?: boolean;
  onChange?: (tab: number, tabName?: string) => void;
  manualActiveTabIndex?: number;
  append?: JSX.Element;
};

const Tabs = ({
  defaultActiveTab = 0,
  showButtons = false,
  justify = true,
  onChange = () => {},
  topRightContent = undefined,
  border = 'default',
  size = null,
  stickyNav = false,
  className = undefined,
  content = [],
  linkable = false,
  manualActiveTabIndex,
  append,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const history = useHistory();

  const setURLParam = useCallback(
    (tabIndex: number) => {
      history.replace({
        search: `?${TAB_INDEX_PARAM}=${tabIndex.toString()}`,
      });
    },
    [history]
  );

  const toggleTab = useCallback(
    (tab: number, tabName?: string) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
        (document.activeElement as HTMLButtonElement).blur();

        if (onChange) {
          onChange(tab, tabName);
          if (linkable) setURLParam(tab);
        }
      }
    },
    [activeTab, setActiveTab, onChange, linkable, setURLParam]
  );

  const borderClass: Record<TabsBorder, string> = {
    bottom: 'bordered-bottom',
    top: 'bordered-top',
    default: '',
  };

  useEffect(() => {
    const { search } = history.location;
    if (!linkable) return;
    if (search) return;

    history.replace({
      search: `?${TAB_INDEX_PARAM}=${DEFAULT_ACTIVE_TAB}`,
    });
    // We set search param only on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!manualActiveTabIndex || manualActiveTabIndex === activeTab) return;
    setActiveTab(manualActiveTabIndex);
    setURLParam(manualActiveTabIndex);
  }, [manualActiveTabIndex, activeTab]);

  return (
    <div
      className={classnames('tabs', borderClass[border], className, {
        'tabs-sm': size === 'sm',
        'tabs-lg': size === 'lg',
        'nav-justified': justify,
      })}
    >
      <div className="tabs-wrap">
        <div className="tabs-nav-wrap">
          <Nav
            tabs
            className={classnames({
              'sticky-nav': stickyNav,
            })}
          >
            {content.map((item, index) => {
              const idx = item.tabIndex ?? index;
              return (
                <NavItem key={`${idx}.link`}>
                  {item.tabName && (
                    <NavLink
                      className={classnames({
                        active: activeTab === idx,
                      })}
                      onClick={() => toggleTab(idx, item.tabName)}
                      tag="button"
                      type="button"
                      {...item.tabLinkProps}
                    >
                      <div className="d-flex align-items-center">
                        {item.tabIcon && (
                          <div className="mr-1">{item.tabIcon}</div>
                        )}
                        {item.tabName}
                      </div>
                    </NavLink>
                  )}
                </NavItem>
              );
            })}
            {topRightContent && (
              <div className="top-right-content">{topRightContent}</div>
            )}
          </Nav>
          {append}
        </div>
        <TabContent activeTab={activeTab}>
          {content.map((item, index) => {
            const idx = item.tabIndex ?? index;
            return (
              <TabPane key={`${idx}.content`} tabId={idx}>
                {item.tabContent}
              </TabPane>
            );
          })}
        </TabContent>
      </div>
      {showButtons && (
        <div className="tab-buttons">
          <div>
            <Button
              color="secondary"
              disabled={activeTab === 0}
              onClick={() => toggleTab(activeTab - 1)}
            >
              Previous
            </Button>
          </div>
          <div>
            <Button
              color="primary"
              disabled={activeTab === content.length - 1}
              onClick={() => toggleTab(activeTab + 1)}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tabs;
