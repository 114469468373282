import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  type PropsWithChildren,
} from 'react';
import { useHistory } from 'react-router';

const SCROLL_POSITIONS_KEY = 'scrollPositions';

if (history.scrollRestoration) {
  history.scrollRestoration = 'manual';
}
interface IScrollRestorationContext {
  clearScrollPositions: () => void;
  saveScrollPosition: () => void;
}

const ScrollRestorationContext = createContext<IScrollRestorationContext>({
  clearScrollPositions: () => {},
  saveScrollPosition: () => {},
});

const scrollPositions = JSON.parse(
  sessionStorage.getItem(SCROLL_POSITIONS_KEY) || '{}'
);

const getScrollPosition = (key: string) => scrollPositions[key];

const setScrollPosition = (key: string, value: number) => {
  scrollPositions[key] = value;
  sessionStorage.setItem(SCROLL_POSITIONS_KEY, JSON.stringify(scrollPositions));
};

const clearScrollPositions = () => {
  Object.keys(scrollPositions).forEach(key => {
    delete scrollPositions[key];
  });
  sessionStorage.removeItem(SCROLL_POSITIONS_KEY);
};

const getLocationKey = (location: Location) =>
  `${location.pathname}${location.search}`;

const ScrollRestoration: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const history = useHistory();

  const scrollWhenLoaded = useCallback(
    (locationKey: string, targetScrollY: number) => {
      const resizeObserver = new ResizeObserver(() => {
        if (getLocationKey(window.location) !== locationKey) {
          resizeObserver.disconnect();
        }

        if (
          document.documentElement.scrollHeight >=
          targetScrollY + window.innerHeight
        ) {
          window.scrollTo({ top: targetScrollY, left: 0, behavior: 'smooth' });
          resizeObserver.disconnect();
        }
      });

      resizeObserver.observe(document.documentElement);
    },
    []
  );

  useEffect(() => {
    const unlisten = history.listen((_, action) => {
      if (action === 'POP') {
        const locationKey = getLocationKey(window.location);
        const scrollY = getScrollPosition(locationKey);

        if (scrollY) {
          scrollWhenLoaded(locationKey, scrollY);
        } else {
          window.scrollTo({ top: 0, left: 0 });
        }
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  const saveScrollPosition = useCallback(() => {
    setScrollPosition(
      getLocationKey(window.location),
      Math.floor(window.scrollY)
    );
  }, []);

  const contextValue = useMemo(
    () => ({ clearScrollPositions, saveScrollPosition }),
    [saveScrollPosition]
  );

  return (
    <ScrollRestorationContext.Provider value={contextValue}>
      {children}
    </ScrollRestorationContext.Provider>
  );
};

export const useScrollRestoration = () => useContext(ScrollRestorationContext);

export default ScrollRestoration;
