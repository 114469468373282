import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkSquareAlt,
  faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons';

const mailtoLink = `mailto:support@getstoryteller.com?subject=Storyteller CMS Support&body=${encodeURIComponent(`Hi Storyteller Support,
  --> Replace this line with a description of the issue.

  ---

  Please don't delete anything below this line, it will help us debug the issue.

  ${document.title} | ${window.location} | ${navigator.userAgent}`)}`;

const SupportDropdown = () => {
  const [helpMenuOpen, setHelpMenuOpen] = useState(false);

  const toggleHelpMenu = () => {
    setHelpMenuOpen(prevState => !prevState);
  };

  return (
    <Dropdown
      isOpen={helpMenuOpen}
      toggle={toggleHelpMenu}
      className="topbar-dropdown-nav"
    >
      <DropdownToggle
        className="topbar-dropdown-trigger"
        onClick={event => event.currentTarget.blur()}
        title="Support"
      >
        <FontAwesomeIcon aria-label="Help Menu" icon={faQuestionCircle} />
      </DropdownToggle>
      <CSSTransition in={helpMenuOpen} classNames="dropdown" timeout={200}>
        <DropdownMenu right>
          <DropdownItem header>Support</DropdownItem>
          <DropdownItem
            href="https://docs.getstoryteller.com/documents/user-guide"
            target="_blank"
          >
            User Guide{' '}
            <FontAwesomeIcon
              icon={faExternalLinkSquareAlt}
              className="text-muted"
            />
          </DropdownItem>
          <DropdownItem href={mailtoLink} target="_blank">
            Report an issue
          </DropdownItem>
        </DropdownMenu>
      </CSSTransition>
    </Dropdown>
  );
};

export default SupportDropdown;
