import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Collapse as ReactstrapCollapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import './Collapse.scss';

/** Collapsible component for hiding content */

interface Props {
  children: React.ReactNode;
  className?: string;
  customTrigger?: React.ReactNode;
  defaultOpen?: boolean;
  onTrigger?: (isOpen: boolean) => void;
  title?: string;
  triggerButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

const Collapse = ({
  children,
  className,
  customTrigger,
  defaultOpen = false,
  onTrigger = (isOpen: boolean) => {},
  title = '',
  triggerButtonProps = {},
}: Props) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggle = () => {
    setIsOpen(!isOpen);
    onTrigger(!isOpen);
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  const CollapseTrigger = (
    <div>
      <h4 className="title">{title}</h4>
      <FontAwesomeIcon icon={faChevronDown} className="trigger-icon" />
    </div>
  );

  return (
    <div className={classnames(className, 'collapse-container')}>
      <button
        className={classnames('trigger unstyled-button', {
          open: isOpen,
          closed: !isOpen,
        })}
        color="primary"
        onClick={toggle}
        type="button"
        {...triggerButtonProps}
      >
        {customTrigger || CollapseTrigger}
      </button>
      <ReactstrapCollapse isOpen={isOpen}>
        <div className="collapse-content">{children}</div>
      </ReactstrapCollapse>
    </div>
  );
};

export default Collapse;
