import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { useScrollRestoration } from 'infrastructure/scrollRestoration/ScrollRestoration';

type LinkProps = RouterLinkProps & {
  scrollBehaviour?: 'persist' | 'reset' | 'none';
};

/**
 * Wrapper around react-router-dom's Link component with scroll restoration.
 */
function Link({ children, scrollBehaviour = 'persist', ...props }: LinkProps) {
  const { clearScrollPositions, saveScrollPosition } = useScrollRestoration();

  return (
    <RouterLink
      {...props}
      onClick={event => {
        if (scrollBehaviour === 'persist') {
          saveScrollPosition();
        } else if (scrollBehaviour === 'reset') {
          clearScrollPositions();
        }

        props.onClick?.(event);
      }}
    >
      {children}
    </RouterLink>
  );
}

export default Link;
