import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faDownload,
  faPencil,
  faSyncAlt,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { Button, Link } from 'components';

const MOVE_DIRECTION = {
  up: 'up',
  down: 'down',
};

export const RemoveButton = ({
  onRowRemove,
  disableRowRemove,
  row,
  tableId,
}) => (
  <Button
    data-testid={`${tableId}__removeBtn_${row.index}`}
    buttonStyle="outline"
    color="danger"
    size="sm"
    onClick={() => onRowRemove(row)}
    disabled={disableRowRemove && disableRowRemove(row)}
    tooltipProps={{
      id: `deleteBtn_${row.index}`,
      tooltipContents: 'Delete',
    }}
  >
    <FontAwesomeIcon icon={faTrashAlt} />
  </Button>
);

export const EditButton = ({
  getRowEditPath,
  disableRowEdit,
  row,
  tableId,
}) => (
  <Button
    data-testid={`${tableId}__editBtn_${row.index}`}
    color="primary"
    size="sm"
    disabled={disableRowEdit && disableRowEdit(row)}
    to={getRowEditPath(row)}
    tag={Link}
  >
    <FontAwesomeIcon icon={faPencil} /> Edit
  </Button>
);

export const ButtonUp = ({ onReorder, firstElementId, row, tableId }) => {
  const onClick = useCallback(
    () => onReorder({ id: row.original.id, direction: MOVE_DIRECTION.up }),
    [onReorder, row.original.id]
  );

  return (
    <Button
      data-testid={`${tableId}__upBtn_${row.index}`}
      color="info"
      size="sm"
      onClick={onClick}
      disabled={row.original.id === firstElementId}
      tooltipProps={{
        id: `moveUpBtn_${row.index}`,
        tooltipContents: 'Move up',
      }}
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </Button>
  );
};

export const ButtonDown = ({ onReorder, lastElementId, row, tableId }) => {
  const onClick = useCallback(
    () => onReorder({ id: row.original.id, direction: MOVE_DIRECTION.down }),
    [onReorder, row.original.id]
  );

  return (
    <Button
      data-testid={`${tableId}__downBtn_${row.index}`}
      color="info"
      size="sm"
      onClick={onClick}
      disabled={row.original.id === lastElementId}
      tooltipProps={{
        id: `moveDownBtn_${row.index}`,
        tooltipContents: 'Move down',
      }}
    >
      <FontAwesomeIcon icon={faArrowDown} />
    </Button>
  );
};

export const DownloadButton = ({ onRowDownload, row, tableId }) => (
  <Button
    data-testid={`${tableId}__downloadBtn_${row.index}`}
    buttonStyle="outline"
    color="info"
    size="sm"
    onClick={() => onRowDownload(row)}
    tooltipProps={{
      id: `downloadBtn_${row.index}`,
      tooltipContents: 'Download',
    }}
  >
    <FontAwesomeIcon icon={faDownload} />
  </Button>
);

export const GetLatestButton = ({ onRowGetLatest, row, tableId }) => (
  <Button
    data-testid={`${tableId}__downloadBtn_${row.index}`}
    color="primary"
    size="sm"
    onClick={() => onRowGetLatest(row)}
  >
    <FontAwesomeIcon icon={faSyncAlt} /> Get Latest
  </Button>
);

export const CustomButton = ({
  onCustomButton,
  row,
  tableId,
  text,
  className,
  icon,
  iconAfter,
}) => (
  <button
    data-testid={`${tableId}__customdBtn_${row.index}`}
    className={cx('btn', className)}
    type="button"
    onClick={() => onCustomButton(row)}
    icon={icon && <FontAwesomeIcon icon={icon} />}
    iconAfter={iconAfter && <FontAwesomeIcon icon={iconAfter} />}
  >
    {text}
  </button>
);

RemoveButton.defaultProps = {
  tableId: '',
};

EditButton.defaultProps = {
  tableId: '',
};

RemoveButton.propTypes = {
  onRowRemove: PropTypes.func.isRequired,
  row: PropTypes.shape({ index: PropTypes.number }).isRequired,
  tableId: PropTypes.string,
  disableRowRemove: PropTypes.func,
};

EditButton.propTypes = {
  getRowEditPath: PropTypes.func.isRequired,
  row: PropTypes.shape({ index: PropTypes.number }).isRequired,
  tableId: PropTypes.string,
  disableRowEdit: PropTypes.func,
};

CustomButton.propTypes = {
  onCustomButton: PropTypes.func.isRequired,
  row: PropTypes.shape({ index: PropTypes.number }).isRequired,
  tableId: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.shape(),
  iconAfter: PropTypes.shape(),
};
