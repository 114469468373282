import React, { ReactElement, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as Btn } from 'reactstrap';
import cx from 'classnames';
import Tooltip, { type TooltipProps } from 'components/Tooltip/Tooltip';
import type { ButtonProps } from 'reactstrap';

import './Button.scss';

export type ButtonAlignment = 'left' | 'center' | 'right';
export type ButtonIconGap = 'sm' | 'md';
export type ButtonFontWeight = 'normal' | 'bold';

/** Wraps the Reactstrap button so we can add loading state,
 * and some useful style props to make usage more consistent
 * and reliable.
 */

export interface ButtonPropsCombined extends ButtonProps {
  /** Also accepts all the props listed here: https://reactstrap.github.io/components/buttons/ */

  /** Aligns the button content */
  alignment?: ButtonAlignment;
  /** Outline can be used for secondary actions */
  buttonStyle?: 'link' | 'outline' | 'solid';
  /** Additional classes for non-standard use cases */
  className?: string;
  /** Bootstrap colors */
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'dark-grey'
    | 'black-tertiary'
    | 'none';
  /** Disabled attribute and class */
  disabled?: boolean;
  /** Font weight */
  fontWeight?: ButtonFontWeight;
  /** Icon note, automatically added so it's always in the same place */
  icon?: ReactElement | null;
  /** Place the icon after the text */
  iconAfter?: boolean;
  /** Gap between icon and text */
  iconGap?: ButtonIconGap;
  /** If true, the button will be used as a trigger for a popover */
  isPopoverTrigger?: boolean;
  /** Loading state */
  loading?: boolean;
  /** Small or large */
  size?: 'sm' | 'md' | 'lg';
  /** Html type attribute */
  type?: 'button' | 'submit' | 'reset';
  /** Optional tooltip props */
  tooltipProps?: TooltipProps;
  href?: string;
  to?: string;
  target?: string;
  rel?: string;
}

const Button: React.FC<ButtonPropsCombined> = ({
  alignment = 'center',
  buttonStyle = 'solid',
  children,
  className,
  color = 'none',
  disabled = false,
  fontWeight = 'bold',
  icon,
  iconAfter = false,
  iconGap = 'sm',
  isPopoverTrigger,
  loading = false,
  type = 'button',
  tooltipProps,
  size,
  ...props
}) => {
  const bootstrapClasses = {
    link: `text-${color}`,
    outline: '',
    solid: '',
  };
  const loadingIndicatorVariant = props.outline
    ? 'loading-indicator-outline'
    : {
        link: 'loading-indicator-link',
        outline: 'loading-indicator-outline',
        solid: 'loading-indicator-solid',
      }[buttonStyle];

  const isIconOnly = useMemo(
    () =>
      (React.isValidElement(children) && children.type === FontAwesomeIcon) ||
      (!children && icon),
    [children, icon]
  );

  const iconClassName =
    (!children || isIconOnly) && !isPopoverTrigger && 'btn-icon';

  const renderButton = () => (
    <Btn
      className={cx(
        bootstrapClasses[buttonStyle],
        className,
        iconClassName,
        `font-weight-${fontWeight}`,
        {
          [tooltipProps?.containerClassName || '']:
            tooltipProps?.containerClassName && disabled,
        }
      )}
      color={buttonStyle === 'link' ? 'link' : color}
      disabled={disabled || loading}
      outline={buttonStyle === 'outline'}
      type={type}
      size={size}
      {...props}
    >
      <span
        className={cx(`align-${alignment}`, `icon-gap-${iconGap}`)}
        style={{ visibility: loading ? 'hidden' : 'visible' }}
      >
        {!iconAfter && icon && icon} {children} {iconAfter && icon && icon}
      </span>

      {loading && (
        <div className={cx(loadingIndicatorVariant, 'loading-indicator')}>
          <div className={cx('ellipsis-container', { isIconOnly })}>
            <div className="ellipsis-1" />
            <div className="ellipsis-2" />
            <div className="ellipsis-3" />
            <div className="ellipsis-4" />
          </div>
        </div>
      )}
    </Btn>
  );

  return tooltipProps && !disabled ? (
    <Tooltip placement="auto" {...tooltipProps}>
      {renderButton()}
    </Tooltip>
  ) : (
    renderButton()
  );
};

export default Button;
