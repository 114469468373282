import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './DataTableBody.scss';

const DataTableBody = ({
  tableId,
  tableInstance,
  customBodyItem,
  customLastBodyItem,
  gridLayout,
  onRowRemove,
  onRowDuplicate,
  setData,
}) => {
  const { getTableBodyProps, prepareRow, page } = tableInstance;
  const BodyItem = customBodyItem || null;
  const BodyLastItem = customLastBodyItem || null;

  return (
    <tbody
      className={cx('dataTableBody', gridLayout, {
        'dataTableBody--grid': gridLayout,
      })}
      {...getTableBodyProps()}
      data-testid={`${tableId}__tableBody`}
    >
      {page.map((row, index) => {
        prepareRow(row);
        return !BodyItem ? (
          <tr
            {...row.getRowProps()}
            data-testid={`${tableId}__tableBodyRow_${index}`}
            tag={row.original.tag}
          >
            {row.cells.map(cell => (
              <td
                {...cell.getCellProps([
                  {
                    className: cx(cell.column.className, {
                      'flex-grow-0': cell.column.dontGrow,
                    }),
                  },
                ])}
              >
                {cell.render('Cell', { setData })}
              </td>
            ))}
          </tr>
        ) : (
          <BodyItem
            key={row.original.id}
            row={row}
            onRowRemove={onRowRemove}
            onRowDuplicate={onRowDuplicate}
          />
        );
      })}
      {customLastBodyItem && <BodyLastItem />}
    </tbody>
  );
};

DataTableBody.propTypes = {
  // ID for e.g. testing purposes
  tableId: PropTypes.string.isRequired,
  // Table instance object from the react-table useTable hook
  tableInstance: PropTypes.shape({
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    page: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  getRowEditPath: PropTypes.func,
  onRowRemove: PropTypes.func,
  onRowDuplicate: PropTypes.func,
  gridLayout: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  customBodyItem: PropTypes.func,
  customLastBodyItem: PropTypes.func,
  setData: PropTypes.func,
};

DataTableBody.defaultProps = {
  onRowRemove: null,
  getRowEditPath: null,
  gridLayout: false,
};

export default DataTableBody;
