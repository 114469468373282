import React, { ReactElement } from 'react';

import { CardText } from 'reactstrap';
import cx from 'classnames';

import './UIMessage.scss';
import Panel from '../Panel/Panel';

export interface UIMessageProps {
  /** Container class */
  className?: string;
  /** Button or link to lead the user on to a desired flow */
  cta?: ReactElement | null;
  /** Artwork image src if available */
  image?: string;
  /** Message to give more context */
  message?: string | ReactElement;
  /** Title of the blank slate or a very brief description of where the user is to give context */
  title?: string | ReactElement;
}

/** To be used when there's no data for a component or page */

const UIMessage: React.FC<UIMessageProps> = ({
  className,
  title,
  image,
  message,
  cta,
}) => {
  const isTitleString = typeof title === 'string';
  const isMessageString = typeof message === 'string';

  return (
    <Panel className={cx('blank-slate', className)}>
      <>
        {image && (
          <img
            src={image}
            alt={isTitleString ? title : ''}
            className="artwork"
          />
        )}
        {isTitleString ? <h2>{title}</h2> : title}
        {isMessageString ? <CardText>{message}</CardText> : message}
        {cta}
      </>
    </Panel>
  );
};

export default UIMessage;
