import { useCallback } from 'react';
import URL_PARAMS from 'components/v2/DataTable/data/urlParams';
import { SortedColumn } from 'components/v2/DataTable/DataTable';
import getSortingDirection from 'components/v2/DataTable/helpers/getSortingDirection';
import { useScrollRestoration } from 'infrastructure/scrollRestoration/ScrollRestoration';

type ParamKeyValuePairs = Record<string, string>;

const useURLParams = () => {
  const { saveScrollPosition } = useScrollRestoration();

  const handleURLSearchQueryParam = useCallback(
    (searchText?: string) => {
      const url = new URL(window.location.href);
      if (searchText) {
        url.searchParams.set(URL_PARAMS.textSearch, `${searchText}`);
      } else {
        url.searchParams.delete(URL_PARAMS.textSearch);
      }

      const urlHasToChange = url.toString() !== window.location.href;
      if (urlHasToChange) {
        saveScrollPosition();
        window.history.replaceState(null, '', url);
      }
    },
    [saveScrollPosition]
  );

  const setURLParams = useCallback(
    (paramNames: ParamKeyValuePairs, keepHistory?: boolean): string => {
      const url = new URL(window.location.href);
      const params = Object.entries(paramNames);
      params.forEach(([key, value]) => {
        url.searchParams.set(key, value);
      });
      const urlHasToChange = url.toString() !== window.location.href;
      if (urlHasToChange) {
        saveScrollPosition();
        if (keepHistory) {
          window.history.pushState(null, '', url);
        } else {
          window.history.replaceState(null, '', url);
        }
      }
      return url.toString();
    },
    [saveScrollPosition]
  );

  const setURLSortingParams = useCallback(
    (sortingParams: SortedColumn): void => {
      const url = new URL(window.location.href);
      const { id, desc } = sortingParams;
      url.searchParams.set(URL_PARAMS.sortId, `${id}`);
      url.searchParams.set(URL_PARAMS.sorting, `${getSortingDirection(desc)}`);
      const urlHasToChange = url.toString() !== window.location.href;
      if (urlHasToChange) {
        saveScrollPosition();
        window.history.replaceState(null, '', url);
      }
    },
    [saveScrollPosition]
  );

  const unsetURLParams = useCallback(
    (params: string[]): string => {
      const url = new URL(window.location.href);
      params.forEach(paramName => {
        url.searchParams.delete(paramName);
      });
      const urlHasToChange = url.toString() !== window.location.href;
      if (urlHasToChange) {
        saveScrollPosition();
        window.history.replaceState(null, '', url);
      }
      return url.toString();
    },
    [saveScrollPosition]
  );

  const unsetURLSortingParams = useCallback(() => {
    const url = new URL(window.location.href);
    url.searchParams.delete(URL_PARAMS.sortId);
    url.searchParams.delete(URL_PARAMS.sorting);
    const urlHasToChange = url.toString() !== window.location.href;
    if (urlHasToChange) {
      saveScrollPosition();
      window.history.replaceState(null, '', url);
    }
  }, [saveScrollPosition]);

  return {
    handleURLSearchQueryParam,
    setURLParams,
    setURLSortingParams,
    unsetURLParams,
    unsetURLSortingParams,
  };
};

export default useURLParams;
