import React from 'react';
import classnames from 'classnames';
import { Link } from 'components';

import './GlobalTopbar.scss';

export interface GlobalTopbarProps {
  /** Space to put user, help dropdowns etc */
  contentRight?: React.ReactNode;
  /** Open/Closed hamburger classNames */
  hamburgerClassName?: string;
  /** Logo image or element */
  logo?: React.ReactNode;
  /** Open close nav function for mobile */
  toggleNav?: () => void;
}

const GlobalTopbar: React.FC<GlobalTopbarProps> = props => {
  const { contentRight, hamburgerClassName, logo, toggleNav } = props;

  return (
    <div className="global-topbar">
      <div className="topbar-left">
        {toggleNav && (
          <button
            className={classnames(
              'unstyled-button topbar-hamburger',
              hamburgerClassName
            )}
            onClick={toggleNav}
            type="button"
          >
            <div className="hamburger-bar">
              {hamburgerClassName === 'menu-open'
                ? 'Close Navigation'
                : 'Open Navigation'}
            </div>
          </button>
        )}
        {logo && (
          <Link className="topbar-logo" scrollBehaviour="reset" to="/">
            {logo}
          </Link>
        )}
      </div>
      {contentRight && <div className="topbar-right">{contentRight}</div>}
    </div>
  );
};

export default GlobalTopbar;
