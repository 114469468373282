import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faFileVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { Img, ProgressBar } from 'components';
import { FileInfo } from 'infrastructure/uploads/UploadsContext';

/**
 * Formats file size.
 * @param size - file size
 * @returns formatted file size
 */
const fileSizeFormatter = (size: number) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export interface FileUploadRowProps {
  file: FileInfo;
}

const FileUploadRow: React.FC<FileUploadRowProps> = ({ file }) => (
  <div className="upload-preview-row">
    <div className="file-preview">
      {file.type === 'image' ? (
        <div
          style={{
            transform: `rotate(${file.rotation}deg)`,
          }}
        >
          <Img
            alt={file.name}
            blurFill
            containerClassName="thumbnail"
            src={file.src}
          />
        </div>
      ) : (
        <FontAwesomeIcon className="placeholder-icon" icon={faFileVideo} />
      )}
    </div>
    <div className="file-info-container">
      <div className="file-info">
        <div className="file-name">{file.name}</div>
        <small className="file-size text-muted">
          {file.status === 'failed' ? 'Failed' : fileSizeFormatter(file.size)}
        </small>
        {file.status === 'uploading' && (
          <ProgressBar
            color="info"
            value={file.bytesUploaded}
            total={file.size}
            size="xs"
            className="uploading-bar"
          />
        )}
      </div>
    </div>
    <div className="status-icon">
      {file.status === 'uploaded' && (
        <FontAwesomeIcon
          className="text-success"
          fixedWidth
          icon={faCheckCircle}
        />
      )}
      {file.status === 'failed' && (
        <FontAwesomeIcon
          className="text-danger"
          fixedWidth
          icon={faExclamationCircle}
        />
      )}
    </div>
  </div>
);

export default FileUploadRow;
