"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getInputHeight;
/* eslint-disable camelcase */

function getPadding(vertical, top, bottom) {
  var isTopDefined = typeof top === 'number';
  var isBottomDefined = typeof bottom === 'number';
  var isVerticalDefined = typeof vertical === 'number';
  if (isTopDefined && isBottomDefined) {
    return top + bottom;
  }
  if (isTopDefined && isVerticalDefined) {
    return top + vertical;
  }
  if (isTopDefined) {
    return top;
  }
  if (isBottomDefined && isVerticalDefined) {
    return bottom + vertical;
  }
  if (isBottomDefined) {
    return bottom;
  }
  if (isVerticalDefined) {
    return 2 * vertical;
  }
  return 0;
}
function getInputHeight(_ref, small) {
  var _ref$font$input = _ref.font.input,
    lineHeight = _ref$font$input.lineHeight,
    lineHeight_small = _ref$font$input.lineHeight_small,
    _ref$spacing = _ref.spacing,
    inputPadding = _ref$spacing.inputPadding,
    displayTextPaddingVertical = _ref$spacing.displayTextPaddingVertical,
    displayTextPaddingTop = _ref$spacing.displayTextPaddingTop,
    displayTextPaddingBottom = _ref$spacing.displayTextPaddingBottom,
    displayTextPaddingVertical_small = _ref$spacing.displayTextPaddingVertical_small,
    displayTextPaddingTop_small = _ref$spacing.displayTextPaddingTop_small,
    displayTextPaddingBottom_small = _ref$spacing.displayTextPaddingBottom_small;
  var calcLineHeight = small ? lineHeight_small : lineHeight;
  var padding = small ? getPadding(displayTextPaddingVertical_small, displayTextPaddingTop_small, displayTextPaddingBottom_small) : getPadding(displayTextPaddingVertical, displayTextPaddingTop, displayTextPaddingBottom);
  return parseInt(calcLineHeight, 10) + 2 * inputPadding + padding;
}